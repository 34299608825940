import React, { useState } from "react";
import { graphql } from "gatsby";
import { Switch } from "@headlessui/react";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import Checkmark from "../images/global/checkmark.svg";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  const [isMonthly, setIsMonthly] = useState(false);

  return (
    <Layout>
      <SearchEngineOptimization
        title="Partner Pricing | Real Synch | Real Estate Automation"
        description="Can your software provide value for real estate teams? If so, we want to partner with you and have pricing plans to match your commitment."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="bg-primary-900 pt-12 pb-20 md:mb-7 md:bg-white md:pt-12 md:pb-0">
        <div className="container">
          <header className="mx-auto mb-10 text-center md:mb-12 md:max-w-[720px]">
            <h1 className="text-white md:text-typography-heading">
              Integrate Your Software With Real Synch
            </h1>
            <p className="text-white/80 md:text-typography-body">
              Can your software provide value for real estate teams? If so, we
              want to partner with you and have pricing plans to match your
              commitment.
            </p>
          </header>

          <div className="mb-12 flex items-center justify-center space-x-3">
            <div className="text-sm font-extrabold uppercase text-white md:text-typography-heading">
              Annual
            </div>
            <Switch
              checked={isMonthly}
              onChange={setIsMonthly}
              className={`${isMonthly}
          relative inline-flex h-6 w-[44px] flex-shrink-0 cursor-pointer rounded-xl bg-gradient-to-br from-primary-400 to-primary-700`}
            >
              <span className="sr-only">Use setting</span>
              <span
                aria-hidden="true"
                className={`${
                  isMonthly ? "translate-x-[22px]" : "translate-x-0.5"
                }
            pointer-events-none inline-block h-5 w-5 translate-y-0.5 transform rounded-full bg-white shadow-5xl ring-0 transition duration-200 ease-in-out`}
              />
            </Switch>
            <div className="text-sm font-extrabold uppercase text-white md:text-typography-heading">
              Monthly
            </div>
          </div>

          <div className="grid gap-y-10 md:hidden md:grid-cols-1">
            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Free
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  $0
                </div>
                <div className="font-body text-sm">/ month</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>1 Synch</li>
                <li>1,000 Tasks</li>
                <li>Access to CRMs</li>
              </ul>
              <ButtonSolid
                modal="modal-contact"
                text="Schedule a Consult"
                className="w-full"
              />
            </div>

            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Starter
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  {isMonthly ? "$250" : "$200"}
                </div>
                <div className="font-body text-sm">/ month</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>25 Synchs</li>
                <li>5,000 Tasks</li>
                <li>Access to CRMs</li>
                <li>Filters</li>
              </ul>
              <ButtonSolid
                href={
                  isMonthly
                    ? "https://app.realsynch.com/register?plan=partnerstarter&version=5"
                    : "https://app.realsynch.com/register?plan=annualpartnerstarter&version=5"
                }
                outboundLink={true}
                text="Choose Starter"
                className="w-full"
              />
            </div>

            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Advanced
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  {isMonthly ? "$750" : "$600"}
                </div>
                <div className="font-body text-sm">/ month</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>75 Synchs</li>
                <li>15,000 Tasks</li>
                <li>Access to CRMs</li>
                <li>Filters</li>
                <li>Dedicated Support</li>
                <li>Analytics Report</li>
              </ul>
              <ButtonSolid
                href={
                  isMonthly
                    ? "https://app.realsynch.com/register?plan=advanced&version=5"
                    : "https://app.realsynch.com/register?plan=annualadvanced&version=5"
                }
                outboundLink={true}
                text="Choose Advanced"
                className="w-full"
              />
            </div>

            <div className="rounded-3xl bg-white p-6">
              <div className="mb-4 inline-flex items-center justify-center rounded bg-primary-700/10 px-3 py-1 text-center font-bold text-primary-700">
                Elite
              </div>
              <div className="mb-6 flex items-center space-x-1">
                <div className="text-4xl font-bold text-typography-heading">
                  {isMonthly ? "$3K" : "$2,400"}
                </div>
                <div className="font-body text-sm">/ month</div>
              </div>
              <div className="mb-5 h-px bg-gray-200"></div>
              <ul className="styled-list-checkmark mb-6 font-semibold">
                <li>300 Synchs</li>
                <li>50,000 Tasks</li>
                <li>Access to CRMs</li>
                <li>Filters</li>
                <li>Dedicated Support</li>
                <li>Analytics Report</li>
              </ul>
              <ButtonSolid
                href={
                  isMonthly
                    ? "https://app.realsynch.com/register?plan=elite&version=5"
                    : "https://app.realsynch.com/register?plan=annualelite&version=5"
                }
                outboundLink={true}
                text="Choose Elite"
                className="w-full"
              />
            </div>
          </div>

          <div className="hidden space-x-10 pl-7 pr-2 md:flex">
            <div className="min-w-[148px] lg:min-w-[208px]"></div>
            <div className="flex w-full md:grid md:grid-cols-4">
              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Free
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    $0
                  </div>
                  <div className="font-body text-sm">/ mo</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  modal="modal-contact"
                  text="Schedule a Consult"
                  className="w-full min-w-0"
                />
              </div>

              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Starter
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    {isMonthly ? "$250" : "$200"}
                  </div>
                  <div className="font-body text-sm">/ mo</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=partnerstarter&version=5"
                      : "https://app.realsynch.com/register?plan=annualpartnerstarter&version=5"
                  }
                  outboundLink={true}
                  text="Choose Starter"
                  className="w-full min-w-0"
                />
              </div>

              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Advanced
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    {isMonthly ? "$750" : "$600"}
                  </div>
                  <div className="font-body text-sm">/ mo</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=advanced&version=5"
                      : "https://app.realsynch.com/register?plan=annualadvanced&version=5"
                  }
                  outboundLink={true}
                  text="Choose Advanced"
                  className="w-full min-w-0"
                />
              </div>

              <div className="px-2.5 pb-4 lg:px-5">
                <div className="mb-3.5 text-lg font-bold text-typography-heading">
                  Elite
                </div>
                <div className="mb-3 flex items-center space-x-1">
                  <div className="text-3xl font-bold text-typography-heading lg:text-4xl">
                    {isMonthly ? "$3K" : "$2,400"}
                  </div>
                  <div className="font-body text-sm">/ mo</div>
                </div>

                <ButtonSolid
                  altStyle={3}
                  href={
                    isMonthly
                      ? "https://app.realsynch.com/register?plan=elite&version=5"
                      : "https://app.realsynch.com/register?plan=annualelite&version=5"
                  }
                  outboundLink={true}
                  text="Choose Elite"
                  className="w-full min-w-0"
                />
              </div>
            </div>
          </div>

          <div className="hidden space-x-10 rounded-3xl bg-primary-900 py-2 pl-7 pr-2 md:flex">
            <div className="min-w-[148px] lg:min-w-[208px]">
              <div className="flex h-[66px] items-center font-semibold text-white">
                Synchs
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Tasks
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Access to CRMs
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Filters
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Dedicated Support
              </div>
              <div className="flex h-[66px] items-center font-semibold text-white">
                Analytics Report
              </div>
            </div>

            <div className="flex w-full rounded-3xl bg-white md:grid md:grid-cols-4">
              <div className="border-r border-gray-200">
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  1
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  1,000
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700"></div>
              </div>

              <div className="border-r border-gray-200">
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  25
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  5,000
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700"></div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700"></div>
              </div>

              <div className="border-r border-gray-200">
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  75
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  15,000
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
              </div>

              <div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  300
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  50,000
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center border-b border-gray-200 font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
                <div className="flex h-[66px] items-center justify-center font-extrabold text-primary-700">
                  <img src={Checkmark} alt="Checkmark" />
                </div>
              </div>
            </div>
          </div>

          <section
            className="mt-12 rounded-4xl py-16 px-6 md:mt-18 md:py-20"
            style={{
              background: "linear-gradient(270deg, #E2DAFE 0%, #E9EFFD 100%)",
            }}
          >
            <div className="text-center">
              <h2 className="mb-3.5">Custom Plan</h2>
              <p>
                Don’t see the right fit? Schedule a call directly with our team
                to build a custom plan.
              </p>
              <ButtonSolid modal="modal-contact" text="Book a Consult" />
            </div>
          </section>
        </div>
      </section>
    </Layout>
  );
};

export const query = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Global.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Global.jpg" }
    ) {
      publicURL
    }
  }
`;

export default Page;
